import apiEndpoints from '@/models/common/api-endpoints';
import dayjs from 'dayjs';
import draggable from 'vuedraggable';
import generalFunctions from '@/models/common/general-functions';
import { mapGetters } from 'vuex';
import tipsAndTricks from '@/components/TipsAndTricks';
import witnessDateFilter from '@/components/WitnessDateFilter';
import witnessSummary from '@/components/WitnessSummary.vue';

export default {

  name: 'witness_dashboard',
  components: {
    draggable,
    tipsAndTricks,
    witnessDateFilter,
    witnessSummary
  },

  /*
  ********
  * Data *
  ********
  */ 

  data () {
    return {
      witnessDashboardConfigs: {
        loading: true,
        error: false,
        ready: false,
        dashboardId: undefined,
        configs: undefined,
        originalConfigs: undefined,
        name: '',
        originalName: ''
      },
      witnessInstallList: {
        loading: true,
        error: false,
        ready: false,
        list: [],
        currentSelected: undefined
      },
      edit_dialog: false,
      edit_object_valid: true,
      editTitle: 'Rename item',
      editedName: '',
      originalName: '',

      editMetricsConfigDialog: false,
      editMetricsConfigValid: false,
      editMetricsConfigTitle: 'Rename',
      editMetricsConfigNewName: '',
      editMetricsConfigProcessing: false,
      originalIndex: undefined,
      edit_processing: false,
      toggleManagementControls: false,
      toggleShowDescription: false,
      reportLoading: false,
      subscribedCheckboxValue: false,
      subscribedValue: false,
      subscriptionStatusUnknown: true,
      rules: {
        required: value => !!value || 'A value is required',
        minLength: value => {
          return (value || '').length > 2 || 'Name must contain at least 3 characters';
        }
      }

    };
  },

  /*
  ************
  * Computed *
  ************
  */

  computed: {
    ...mapGetters({
      dateFilter: 'dateFilter'
    })
  },

  /*
  *********
  * Watch *
  *********
  */
  watch: {
    subscribedCheckboxValue: function (newVal, oldVal) {
      if (newVal !== oldVal && this.subscribedCheckboxValue !== this.subscribedValue) {
        // user is trying to change their subscription state
        this.subscriptionStatusUnknown = true;
        if (newVal === true) {
          this.subscribeToReport();
        } else {
          this.unsubscribeFromReport();
        }
      }
    }
  },

  /*
  ***********
  * Methods *
  ***********
  */
  methods: {
    clickRenameMetricsConfig: async function () {
      // make a copy of the current name to be edited
      this.editMetricsConfigNewName = generalFunctions.deepCopy(this.witnessDashboardConfigs.name);

      // show the dialog
      this.editMetricsConfigDialog = true;
    },
    clickCloseEditMetricsConfig: function () {
      // close the dialog
      this.editMetricsConfigDialog = false;
    },
    clickSaveEditMetricsConfig: async function () {
      // activate the processing state of the dialog
      this.editMetricsConfigProcessing = true;

      // replace the old name with a deep copy of the new name
      this.witnessDashboardConfigs.name = generalFunctions.deepCopy(this.editMetricsConfigNewName);

      // persist the changed copy of the configuration to the API
      await this.updateWitnessDashboardConfigs();

      // deactivate the processing state
      this.editMetricsConfigProcessing = false;

      // close the dialog
      this.clickCloseEditMetricsConfig();
    },
    retrieveSubscriptionStatus: async function () {
      // get the subscription status
      const response = await apiEndpoints.getWitnessDashboardsReportsSubscriptionStatus();
      
      if (response.result) {
        
        // check if the user is subscribed to this metric config/dashboard
        let subscribed = false;
        if (Array.isArray(response.data) && response.data.length > 0) {
          response.data.forEach((item) => {
            if (item.dashboardid === this.witnessDashboardConfigs.dashboardId) {
              subscribed = true;
            }
          });
        }

        // Set the new state
        this.subscribedValue = subscribed;
        this.subscribedCheckboxValue = subscribed;
        this.subscriptionStatusUnknown = false;
      }
      else {
        this.subscriptionStatusUnknown = true;
        return;
      }
      
    },
    subscribeToReport: async function () {
      // prepare the body of the request
      const body = {
        timeOffsetHours: -(new Date().getTimezoneOffset() / 60),
        interval: 'Monthly',
        startDate: '2020-01-05',
        reportID: this.witnessDashboardConfigs.dashboardId
      };

      // send the request
      const response = await apiEndpoints.postWitnessDashboardsReportSubscription(body);

      if (response.result) {
        // refresh the subscription state
        this.retrieveSubscriptionStatus();
      }
    },
    unsubscribeFromReport: async function () {
      // send the request
      const response = await apiEndpoints.deleteWitnessDashboardReportSubscription(this.witnessDashboardConfigs.dashboardId);

      if (response.result) {
        // refresh the subscription state
        this.retrieveSubscriptionStatus();
      }
    },
    clickSubscriptionCheckbox: async function () {
      alert(this.subscribedValue);
    },
    clickGeneratePDF: async function () {
      // set loading state
      this.reportLoading = true;

      // early out to act as a guard against missing dependant vars
      if (!this.witnessDashboardConfigs || !this.witnessDashboardConfigs.dashboardId || this.witnessDashboardConfigs.dashboardId === '') {
        return;
      }

      let baseurl = apiEndpoints.apiDetails.url + '/Dashboards/' + this.witnessDashboardConfigs.dashboardId + '/pdf?date=' + dayjs(this.dateFilter.endDate).format('YYYY-MM');
      // PDF will require date as aprt of card 433

      // Prepare config patch
      const configPatch = {
        // stop axios meddling with the response body (it seems to automatically assume it is text and convert it to the browsers local char encoding)
        // setting responseType to blob forces axios to treat the body as binary
        responseType: 'blob'
      };
      
      const response = await apiEndpoints.generatePDF(baseurl, configPatch);

      if (response.result) {
        
        // guard against empty data
        if (!response.data || !response?.config?.responseType == 'blob') {
          this.reportLoading = false;
          return;
        }

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // adapted from: https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // save the blob as a file

          window.navigator.msSaveOrOpenBlob(response.data);

          // set the loading state
          this.reportLoading = false;

          // early out
          return;
        }

        // create a url to the blob
        const url = (window.URL ? window.URL : window.webkitURL).createObjectURL(response.data);

        // add a hidden link to the DOM
        const hiddenElement = document.createElement('a');
        hiddenElement.href = url;
        hiddenElement.target = '_blank';
        hiddenElement.download = 'riwiq_metrics_report.pdf';

        // simulate a click on the hidden element to trigger the download
        hiddenElement.click();        
      }

      // set the loading state
      this.reportLoading = false;
    },
    removeMetric: async function (index) {

      // confirm the deletion
      if (!confirm('Are you sure you want to delete the item \'' + this.witnessDashboardConfigs.configs[index].name + '\'?')) {
        return;
      }

      // remove the entry from the array
      this.witnessDashboardConfigs.configs.splice(index, 1);

      // update the metricConfig on the server
      this.updateWitnessDashboardConfigs();
    },
    editSave: async function () {
      // activate the processing state of the dialog
      this.edit_processing = true;

      // replace the old name with a deep copy of the new name
      this.witnessDashboardConfigs.configs[this.originalIndex].name = generalFunctions.deepCopy(this.editedName);

      // persist the changed copy of the configuration to the API
      await this.updateWitnessDashboardConfigs();

      // deactivate the processing state
      this.edit_processing = false;

      // close the dialog
      this.editClose();
    },
    editClose: function () {
      // clear the states of the holding variables
      this.originalName = '';
      this.editedName = '';
      this.originalIndex = undefined;

      // close the dialogs
      this.edit_dialog = false;
    },
    renameMetric: function (index) {
      // deep copy the current name and index into a holding variable
      this.originalName = generalFunctions.deepCopy(this.witnessDashboardConfigs.configs[index].name);
      this.editedName = generalFunctions.deepCopy(this.witnessDashboardConfigs.configs[index].name);
      this.originalIndex = index;

      // show the dialog
      this.edit_dialog = true;
    },
    retrieveWitnessDashboardConfigs: async function () {
      this.witnessDashboardConfigs.loading = true;
      
      const response = await apiEndpoints.getWitnessDashboardConfigs();

      if (response.result) {
        if (!generalFunctions.isEmptyObject(response.data) && response.data.length > 0) {
          // have at least 1 returned object in the array
          if (!generalFunctions.isEmptyObject(response.data[0]) && response.data[0].config.length > 0) {
            // have at least 1 configuration to display
            // store these configurations:
            this.witnessDashboardConfigs.configs = generalFunctions.deepCopy(response.data[0].config);
            this.witnessDashboardConfigs.originalConfigs = generalFunctions.deepCopy(response.data[0].config);
            this.witnessDashboardConfigs.name = generalFunctions.deepCopy(response.data[0].name);
            this.witnessDashboardConfigs.originalName = generalFunctions.deepCopy(response.data[0].name);
  
            // store the dashboardId
            this.witnessDashboardConfigs.dashboardId = response.data[0].dashboardid;
          } else {
            this.witnessDashboardConfigs.configs = undefined;
          }
        } else {
          this.witnessDashboardConfigs.configs = undefined;
        }
      }

      this.witnessDashboardConfigs.loading = false;
    },
    updateWitnessDashboardConfigs: async function () {
      // early out if nothing has changed
      if ((JSON.stringify(this.witnessDashboardConfigs.originalConfigs) === JSON.stringify(this.witnessDashboardConfigs.configs)) && (this.witnessDashboardConfigs.originalName === this.witnessDashboardConfigs.name)) {
        // nothing changed so exit the function
        return;
      }

      const updatedConfigData = {
        name: this.witnessDashboardConfigs.name,
        config: this.witnessDashboardConfigs.configs
      };

      // update the config on the server
      const putStatus = await apiEndpoints.putWitnessDashboardConfig(this.witnessDashboardConfigs.dashboardId, updatedConfigData);
          
      if (putStatus.result) {
        // update the originalConfigs
        this.witnessDashboardConfigs.originalConfigs = generalFunctions.deepCopy(this.witnessDashboardConfigs.configs);
      }
  
      // close dialog if open
      this.editClose();

    },
    isEmptyObject: generalFunctions.isEmptyObject // for use by Vue
  },
  mounted () {
    this.retrieveWitnessDashboardConfigs().then(() => {
      this.retrieveSubscriptionStatus();
    });
  }
};